<template>
  <div>
    <Header />

    <div class="bg-beige max-w-7xl mt-10 mx-auto p-10 sm:px-6">
      <img class="relative rounded-lg shadow-lg" src="@/assets/images/Home-MacBookPro.png" alt="App screenshot">
    </div>

    <div class="max-w-7xl mx-auto pt-12 px-4 sm:pt-12 sm:px-6 lg:px-8">
      <h2 class="text-center text-gray-400 text-sm font-semibold uppercase tracking-wide">Trusted by over 26,000 forward-thinking companies</h2>
      <div class="mt-8 grid grid-cols-2 gap-8 md:grid-cols-6 lg:grid-cols-5">
        <div class="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
          <img class="h-12" src="https://tailwindui.com/img/logos/tuple-logo-gray-400.svg" alt="Tuple">
        </div>
        <div class="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
          <img class="h-12" src="https://tailwindui.com/img/logos/mirage-logo-gray-400.svg" alt="Mirage">
        </div>
        <div class="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
          <img class="h-12" src="https://tailwindui.com/img/logos/statickit-logo-gray-400.svg" alt="StaticKit">
        </div>
        <div class="col-span-1 flex justify-center md:col-span-3 lg:col-span-1">
          <img class="h-12" src="https://tailwindui.com/img/logos/transistor-logo-gray-400.svg" alt="Transistor">
        </div>
        <div class="col-span-2 flex justify-center md:col-span-3 lg:col-span-1">
          <img class="h-12" src="https://tailwindui.com/img/logos/workcation-logo-gray-400.svg" alt="Workcation">
        </div>
      </div>

      <hr class="mb-16 mt-12" />
    </div>

    <div class="font-extrabold mb-2 text-center text-3xl text-gray-700">Built for the modern classroom</div>
    <div class="mb-12 text-center text-gray-400 text-lg">Focus on what matters, education</div>

    <FAQ />
  </div>
</template>

<script>
import FAQ from '@/components/Home/FAQ'
import Header from '@/components/Home/Header'

export default {
  name: 'Home',
  components: {
    FAQ,
    Header
  }
}
</script>
