<template>
  <div id="app" class="bg-beige">
    <router-view/>
    <Footer />
  </div>
</template>

<script>
import Footer from '@/components/Footer'
export default {
  components: {
    Footer
  }
}
</script>

<style lang="sass">
/* stylelint-disable */
@import "@/styles/main.sass"
</style>
